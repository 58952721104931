




























































































































































































































































































































































































































































.kiwi-ircinput {
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    z-index: 1;
}

.kiwi-ircinput-editor {
    overflow-x: hidden;
    outline: none;

    /* When the contenteditable div is empty firefox makes its height 0px */
    height: 100%;
}

.kiwi-ircinput-editor:empty:not(:focus)::before {
    content: attr(placeholder);
    cursor: text;
}

.kiwi-ircinput-editor img {
    height: 1em;
    vertical-align: -0.1em;
}

