

















































































































































































































































































































.lawnmower-info {
    border: 1px dashed #8c98a6;
    background: #fbf7c0;
    padding: 0.5em;
    margin-bottom: 1em;
}

.kiwi-welcome-simple h2 {
    font-size: 1.7em;
    text-align: center;
    padding: 0;
    margin: 0.5em 0 1em 0;
}

.kiwi-welcome-simple-form {
    width: 90%;
    border-radius: 0.5em;
    padding: 1em;
}

.kiwi-welcome-simple-error {
    text-align: center;
    margin: 1em 0;
    padding: 0.3em;
}

.kiwi-welcome-simple-error span {
    display: block;
    font-style: italic;
}

.kiwi-welcome-simple-section-connection label {
    text-align: left;
    display: inline-block;
    margin-bottom: 0.8em;
    padding: 0 0.5em;
}

.kiwi-welcome-simple-section-connection .input-text input[type="text"] {
    margin-top: 5px;
    padding: 0.3em 1em;
    width: 100%;
    font-size: 1.1em;
    box-sizing: border-box;
}

.kiwi-welcome-simple .input-text {
    font-weight: 600;
    opacity: 0.6;
    font-size: 1.2em;
    margin-bottom: 0.8em;
}

.kiwi-welcome-simple-form input {
    padding: 0.5em;
}

.kiwi-welcome-simple-have-password input {
    font-size: 0.8em;
    margin: 0.8em 0;
    margin-top: 2px;
}

.kiwi-welcome-simple .u-form label span {
    font-size: 1.1em;
    margin-left: 5px;
}

.kiwi-welcome-simple-start {
    font-size: 1.1em;
    cursor: pointer;
}

.kiwi-welcome-simple-start[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
}

.kiwi-welcome-simple-channel {
    margin-bottom: 0.8em;
}

.kiwi-welcome-simple-form .u-submit {
    width: 100%;
    line-height: 50px;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    text-shadow: none;
    margin: 0;
    transition: all 0.2s;
    border: none;
}

.kiwi-welcome-simple .help {
    position: absolute;
    bottom: 0.2em;
    font-size: 0.8em;
    width: 50%;
    text-align: center;
}

.kiwi-welcome-simple .help a {
    text-decoration: underline;
}

/* Styling the preloader */
.kiwi-welcome-simple .fa-spinner {
    position: absolute;
    top: 50%;
    z-index: 999;
    font-size: 100px;
    margin-top: -0.5em;
    left: 50%;
    margin-left: -40px;
}

/** Smaller screen... **/
@media screen and (max-width: 850px) {
    .kiwi-welcome-simple-form {
        left: auto;
        margin: 20px auto 20px auto;
        z-index: 100;
        position: relative;
        top: auto;
        align-self: flex-start;
    }

    .kiwi-welcome-simple p.help {
        position: absolute;
        bottom: 20px;
        width: 100%;
        color: #fff;
        z-index: 100;
    }

    .kiwi-welcome-simple p.help a {
        color: #fff;
    }

    .fa-spinner {
        position: absolute;
        left: 48%;
        top: 50%;
        margin-top: -50px;
        color: #fff;
    }
}

@media (max-width: 400px) {
    .kiwi-welcome-simple-form {
        width: 90%;
    }
}

