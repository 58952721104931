























































































































































































































































































































































































































































































































































































































































































































.kiwi-controlinput {
    z-index: 999;
    position: relative;
    border-top: 1px solid;
}

.kiwi-controlinput,
.kiwi-controlinput-inner {
    padding: 0;
    box-sizing: border-box;
    transition: width 0.2s;
    transition-delay: 0.2s;
}

.kiwi-controlinput-inner i {
    font-size: 120%;
    margin-left: 8px;
    margin-right: 2px;
}

.kiwi-controlinput-inner .kiwi-awaystatusindicator {
    margin-top: 16px;
    margin-left: 10px;
    margin-right: -2px;
}

.kiwi-controlinput-user {
    height: 100%;
    padding: 0 10px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
    line-height: 40px;
    transition: width 0.2s;
    transition-delay: 0.1s;
}

.kiwi-controlinput-selfuser--open .kiwi-controlinput-user {
    width: 286px;
    transition: width 0.2s;
    transition-delay: 0.1s;
}

.kiwi-controlinput-tools {
    /* 38px = 40px controlinput height - margin top+botton */
    line-height: 38px;
    margin: 2px 0 2px 10px;
    border-radius: 7px 0 0 7px;
    cursor: pointer;
}

.kiwi-controlinput-form {
    flex: 1;
    overflow: hidden;
    display: flex;
}

.kiwi-controlinput-send {
    border: none;
    border-radius: 7px;
    margin: 2px 0;
    padding: 0;
    height: 35px;
    text-align: center;
    width: 35px;
    cursor: pointer;
    outline: none;
}

.kiwi-controlinput-inner {
    display: flex;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
}

.kiwi-controlinput-input {
    text-align: left;
    height: 100%;
    outline: none;
    border: none;
}

.kiwi-controlinput-input-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: visible;
    padding: 7px 0 12px 0;
}

.kiwi-controlinput-tool {
    display: inline-block;
    padding: 0 1em;
}

.kiwi-controlinput-tool a {
    cursor: pointer;
}

.kiwi-controlinput-active-tool {
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 100%;
    z-index: 1;
}

.kiwi-controlinput-selfuser {
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 0;
    max-height: 0;
    width: 324px;
    box-sizing: border-box;
    border-radius: 0 6px 0 0;
    opacity: 0;
    border-top: 1px solid;
    border-right: 1px solid;
    overflow: hidden;
}

.kiwi-controlinput-selfuser--open .kiwi-controlinput-selfuser {
    width: 324px;
    max-height: 300px;
    opacity: 1;
}

.kiwi-selfuser-trans-enter,
.kiwi-selfuser-trans-leave-to {
    opacity: 0;
    height: 0;
}

.kiwi-selfuser-trans-enter-to,
.kiwi-selfuser-trans-leave {
    opacity: 1;
}

.kiwi-selfuser-trans-enter-active,
.kiwi-selfuser-trans-leave-active {
    transition: all 0.4s;
}

@media screen and (max-width: 500px) {
    .kiwi-controlinput-user-nick {
        display: none;
    }
}

.kiwi-controlinput-tools-container-expand {
    display: inline-block;
    padding: 0 1em;
}

.kiwi-controlinput-tools-container-expand i {
    transition: transform 0.2s;
}

.kiwi-controlinput-tools-container-expand--inverse i {
    transform: rotateZ(180deg);
}

.kiwi-controlinput-tools-container {
    position: relative;
    display: inline-block;
}

.kiwi-plugin-ui-trans-enter,
.kiwi-plugin-ui-trans-leave-to {
    right: -100%;
}

.kiwi-plugin-ui-trans-enter-to,
.kiwi-plugin-ui-trans-leave {
    right: 0;
}

.kiwi-plugin-ui-trans-enter-active,
.kiwi-plugin-ui-trans-leave-active {
    transition: right 0.2s;
}

@media screen and (max-width: 769px) {
    .kiwi-controlinput-selfuser--open .kiwi-controlinput-selfuser {
        width: 100%;
        border-radius: 0;
        border-right: 0;
    }

    .kiwi-wrap--statebrowser-drawopen .kiwi-controlinput {
        z-index: 0;
    }
    // hide the control input on narrow screens when the self user box is open
    .kiwi-controlinput-portrait-hide {
        display: none;
    }
}

.kiwi-typinguserslist {
    position: absolute;
    top: -24px;
    background: var(--brand-default-bg);
}
